<template>
  <v-container v-if="hasAccess" id="regular-tables" fluid tag="section">
    <v-card class="ma-0" flat>
      <v-row class="ma-0">
        <v-col cols="12" class="d-flex align-center">
          <span class="text-h4 text-sm-h2">Teams </span>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="px-0 me-auto" cols="6" sm="3" lg="2">
          <v-text-field
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            label="Search"
            hide-details
            class="mx-4"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn
            v-if="hasPermission('Create Team')"
            class="float-right ma-0"
            color="primary "
            to="/create-team-member"
            ><v-icon>mdi-plus</v-icon> New member
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        class="px-3"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="teams"
        no-data-text="You don't have any team member "
      >
        <template #item.avatar="{ item }">
          <v-avatar size="32" color="#002">
            <span class="white--text text-h5">{{
              item.name.slice(0, 2).toUpperCase()
            }}</span>
          </v-avatar>
        </template>
        <template #item.job_title="{ item }">
          <v-tooltip
            right
            style="opacity: 1 !important"
            color="white"
            content-class="ms-n16 elevation-2  pa-3 rouned-lg"
          >
            <template #activator="{ on, attrs }">
              <div class="d-flex justify-center">
                <div style="cursor: crosshair" v-bind="attrs" v-on="on">
                  {{
                    item.job_title.length > 1
                      ? `${item.job_title[0]}, ...`
                      : item.job_title[0]
                  }}
                </div>
              </div>
            </template>
            <span style="color: #000" class="text-h5">Roles Assigned</span>
            <div
              class="primary--text"
              v-for="(role, idx) in item.job_title"
              :key="idx"
            >
              <v-icon color="primary">mdi-check</v-icon> {{ role }}
            </div>
          </v-tooltip>
        </template>

        <template #item.branch_assigned="{ item }">
          <v-tooltip
            right
            style="opacity: 1 !important"
            color="white"
            content-class="ms-n16 elevation-2  pa-3 rouned-lg"
          >
            <template #activator="{ on, attrs }">
              <div class="d-flex justify-center">
                <div class="hover-info" v-bind="attrs" v-on="on">
                  {{ item.branches.length }}
                </div>
              </div>
            </template>
            <p style="color: #000" class="text-h5">Branches assigned</p>
            <div
              class="primary--text"
              v-for="(branch, idx) in item.branches"
              :key="idx"
            >
              <v-icon color="primary">mdi-check</v-icon> {{ branch.name }}
            </div>
          </v-tooltip>
        </template>

        <!-- <template #item.users_status="{ item }">
					<v-chip
						style="color: #fff"
						v-if="item.users_status == 0"
						class="ma-2"
						color="red"
						>Inactive</v-chip
					>
					<v-chip
						style="color: #fff"
						v-if="item.users_status == 5"
						class="ma-2"
						color="green"
						>Active</v-chip
					>
				</template> -->

        <template #item.actions="{ item }">
          <div v-if="hasPermission('Edit Team')" class="d-flex align-center">
            <div>
              <v-icon class="mr-3" @click="editmember(item)">
                mdi-pencil
              </v-icon>
            </div>

            <v-switch
              class=""
              dense
              v-model="item.status"
              @click="changememberStatus(item)"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  <v-container fluid v-else class="pa-10" id="regular-tables" tag="section">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card flat>
          <!-- <v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      hasAccess: false,
      User_type: "member",
      items: ["owner", "viewer", "member"],
      teamDialog: false,
      search: "",
      loading: true,
      teams: [],
      search: "",
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
      partner_members: [],
      partner_branches: JSON.parse(localStorage.getItem("partner_branches")),
      selected_branches: [],
      errors: [],
      rules: {
        text_field_rules: [(v) => !!v || "Field is required"],
        numeric_field_rules: [
          (v) => !!v || "Field is required",
          (v) => !isNaN(v) || "Field must have numeric value",
          (v) => !(v < 0) || "Value should be equal or greater than 0",
        ],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
        phoneRules: [
          (v) => !!v || "Phone number is required",
          (v) =>
            (/^(5)[0-9]\d{7}/.test(v) && v.length == 9) ||
            "Phone number must be valid",
        ],
      },
      headers: [
        {
          align: "right",
          class: "pe-0",
          value: "avatar",
        },
        {
          text: "Member ID",
          align: "left",
          class: "ps-0",
          value: "id",
        },
        {
          text: "Member Name",
          align: "center",
          value: "name",
        },

        // {
        // 	text: "Role",
        // 	align: "center",
        // 	value: "job_title"
        // },

        {
          text: "Branches Assigned",
          align: "center",

          value: "branch_assigned",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],

      name: "",
      email: "",
      mobile: "",
      password: "",
      selectedBranches: "",
      selectedRole: "",
      role_list: [],
      dialogDelete: false,
      team_member_id: null,
      parent_id: localStorage.getItem("userid"),
    };
  },

  mounted() {
    this.getTeams();
    this.getRolesList();
  },
  created() {
    this.hasAccess = this.hasPermission(this.$route.meta.permission);
  },
  watch: {
    $route(to, from) {
      this.hasAccess = this.hasPermission(to.meta.permission);
    },
  },
  methods: {
    editmember(member) {
      // this.name = member.name;
      // this.mobile = member.mobile;
      // this.email = member.email;
      // this.team_member_id = member.id;
      // this.selectedRole = member.job_title;
      // this.selectedBranches = member.branches.map((value, index, array) => {
      //   return value.id;
      // });
      this.$router.push({ path: `/edit-team-member/${member.id}` });
    },

    changememberStatus(teamMember) {
      axios
        .post("/api/v3/change-partner-status", {
          team_member_id: teamMember.id,
          status: teamMember.status,
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.getTeams();
          }
        })
        .catch((error) => {
          if (error.response.data.status_code) {
            this.$router.push("/");
          }
          this.showSnackbar("Something went wrong");
        });
    },

    changememberStatusConfirm() {
      this.partner_members.splice(this.editedmemberIndex, 1);
      this.closeDelete();
    },
    getRolesList() {
      //  alert("user"+this.user_id)
      axios
        .get("/api/v3/b2b/roles/list", {
          headers: { "X-Authorization": this.token },
          params: {},
        })
        .then((res) => {
          this.role_list = res.data.partner_roles;
        })
        .catch((err) => console.log(err));
    },

    saveMember() {
      axios
        .post("/api/v3/store-partner-admin", {
          team_member_id: this.team_member_id,
          parent_id: this.parent_id,
          name: this.name,
          password: this.password,
          email: this.email,
          mobile: this.mobile,
          role_id: this.selectedRole,
          branches: this.selectedBranches,
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.getTeams();
            this.teamDialog = false;
          } else {
            this.errors = response.data.errors;
          }
        })
        .catch((error) => {
          if (error.response.data.status_code) {
            this.$router.push("/");
          }
          this.showSnackbar("Something went wrong");
        });
    },
    getTeams() {
      axios
        .get(
          `/api/v3/get-partner-admins?user_id=${localStorage.getItem(
            "userid"
          )}`,
          {
            headers: { "X-Authorization": this.token },
          }
        )
        .then((res) => {
          this.teams = res.data.teams;
        })
        .catch((err) => {
          if (err.response.data.status_code) {
            this.$router.push("/");
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style>
/* .v-data-table table th {
  font-size: 18px !important;
  font-weight: bolder !important;
} */

.hover-info:hover {
  cursor: crosshair;
  display: flex;
  place-content: center;
  place-items: center;
  border: 1px solid rgb(0, 141, 235);
  border-radius: 50px;
  padding: 0.5rem;
  width: 25px;
  height: 25px;
  transition: all 800ms ease-in-out;
}
</style>
